const setupReviews = async () => {
  const reviewSlider = document.querySelector('.publication-reviews-slider')

  if (reviewSlider) {
    import('swiper/css')
    const { default: Swiper, Navigation, Pagination } = await import('swiper')

    Swiper.use([Navigation, Pagination])

    const container = reviewSlider.querySelector('.swiper')
    const numSlides = reviewSlider.querySelectorAll('.swiper-slide').length

    if (numSlides > 1) {
      new Swiper(container, {
        slidesPerView: 1,
        loop: true,
        loopedSlides: numSlides,
        breakpoints: {
          768: {
            slidesPerView: 'auto',
            slidesPerGroupAuto: true,
          },
        },
        pagination: {
          el: reviewSlider.querySelector('.slider-pagination'),
          type: 'custom',
          renderCustom: (_, current, total) => {
            return `${current} of ${total}`
          },
        },
        navigation: {
          nextEl: reviewSlider.querySelector('.slider-control-next'),
          prevEl: reviewSlider.querySelector('.slider-control-prev'),
        },
      })
    }
  }
}

setupReviews()
